// icon:shield-heart | Fontawesome https://fontawesome.com/ | Fontawesome
import * as React from 'react'

function IconLaurelWreath(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      x="0px"
      y="0px"
      viewBox="0 0 54 62"
      {...props}
    >
      <g>
        <defs>
          <rect id="SVGID_1_" x="1.5" y="11.8" transform="matrix(0.3756 -0.9268 0.9268 0.3756 -11.5423 44.0352)" width="50.8" height="37.5" />
        </defs>
        <g>
          <path d="M36.4,57.2c0,0,0.1-0.4,0.1-1c0.2-3.7-1.7-6.6-4.7-8.8C30.5,49.3,30.5,54,36.4,57.2z" />
          <path d="M28.9,51.6c0.9-2.5,0.5-6.6-3.3-10.1C23.6,43.6,24,48.4,28.9,51.6z" />
          <path d="M18.9,11.8c2.4-1,4.8-4.7,4.4-10.1C21.3,2.4,17.6,6.2,18.9,11.8z" />
          <path d="M21.9,43.1c1.9-2.3,2.6-5.9,0.8-10.1C20.1,34.2,18.6,38.4,21.9,43.1z" />
          <path d="M18.1,33.9c1.8-1.1,4.1-3.9,3.5-8.8C19.4,25.5,16.6,28.6,18.1,33.9z" />
          <path d="M17.4,25.5c2.4-0.9,4.6-3.5,4.6-7.8C19.7,17.8,16.9,20.6,17.4,25.5z" />
          <path d="M18,17.4c2-0.4,4.5-2.3,5-6.4C21.4,11,18.3,12.4,18,17.4z" />
          <path d="M36.1,57.7c0,0-0.3,0.3-0.8,0.6c-3.6,2.2-7.9,0.7-11-2.7C27.4,53.4,32.7,55.6,36.1,57.7z" />
          <path d="M28.2,52.3c-3,1.6-8.2,0.1-10.6-5.8C21.4,45.7,25.7,49.5,28.2,52.3z" />
          <path d="M21.3,43.4c-3.5,0.9-7.9-2.4-8.5-8.2C16.5,35.4,19.6,40.1,21.3,43.4z" />
          <path d="M17.3,34.2c0,0-0.4,0-0.9-0.2c-3.4-1.1-4.9-4.9-4.3-9C15.4,25.9,16.8,30.8,17.3,34.2z" />
          <path d="M16.6,25.3c-2.8-0.7-5.1-4.2-3.8-9.2C15.8,17.3,16.6,21.9,16.6,25.3z" />
          <path d="M17.1,17.2c-1.7-0.9-3.7-4-1.6-8.5C18,10.3,17.9,14.3,17.1,17.2L17.1,17.2z" />
        </g>
      </g>
    </svg>

  )
}

export default IconLaurelWreath
