import FirstAvatar from './1.jpg'
import FourthAvatar from './4.jpg'
import SixthAvatar from './6.png'
import EighthAvatar from './8.jpg'
import NinthAvatar from './9.jpg'
import TenthAvatar from './10.jpg'
import EleventhAvatar from './11.jpg'
import TwelfthAvatar from './12.jpg'
import ThirteenthAvatar from './13.jpg'
import SixteenthAvatar from './16.jpg'
import SeventeenthAvatar from './17.jpg'

export const avatars = [
  FirstAvatar,
  FourthAvatar,
  EighthAvatar,
  NinthAvatar,
  SixthAvatar,
  TenthAvatar,
  EleventhAvatar,
  TwelfthAvatar,
  ThirteenthAvatar,
  SixteenthAvatar,
  SeventeenthAvatar,
]
